'use strict';

/*******************************************************************************************/
class UxrFulltext extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			'value': '',
		};
		
		this.onChange = this.onChange.bind(this);
		E.popupFilters.fulltext = {
			filter: this.filter.bind(this),
			value: [new RegExp('', 'i')],
		};
	}
	
	/*---------------------------------------------------------------------*/
	render() {
		return (
			<div {...this.props.htmlProps}>
				<input type="text" value={this.state.value} placeholder="" onChange={this.onChange} />
			</div>
		);
	}
	
	/*---------------------------------------------------------------------*/
	onChange(event) {
		let value = event.target.value;
		this.setState({value});
		E.popupFilters.fulltext.value = value.split(' ').map(function(v) {return new RegExp(v, 'i');});
		GLOBALS.e.applyMarkerFilters();
	}
	
	/*---------------------------------------------------------------------*/
	filter(p) {
		let re = E.popupFilters.fulltext.value;
		let result = re.reduce(function(r, v) { return r && v.test(p.fulltext); }, true);
		return !result;
	}
	
} //class
